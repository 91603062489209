import React,{Fragment} from "react";
import {colors} from "../../Utils/Constants";
import Loader from 'react-loader-spinner';
import { Typography } from "@mui/material";
const IconButton = (props)=>{
  const {execute,type,style,loader,disabled,title} = props;
  const returnStyles = (type)=>{
    let bgc;
    switch (type) {
      case "info":
        bgc = colors.buttons.info
        break;
      case "success":
        bgc = colors.buttons.success
        break;
      case "danger":
        bgc = colors.buttons.danger
        break;
      default:
        bgc = colors.buttons.info
    }
    return {
      background: bgc,
      fontWeight:"bold",
      color:"#fff",
      cursor:disabled?"no-drop":loader?"no-drop":"pointer",
      boxShadow:"0px 8px 15px rgba(0, 0, 0, 0.1)",
      borderRadius:"5px",
      paddingTop:10,
      paddingBottom:10,
      boxSizing:"border-box",
      fontSize:12,
      ...style
    }
  }
  return(
    <Fragment>
      <button onClick={execute} className={disabled?"btn":"btn minisizeable"} style={returnStyles(type)} disabled={disabled?disabled:loader}>
        {
          (loader)?
          <Loader
             type={"TailSpin"}
             color={"#fff"}
             height={20}
             width={20}
             visible={true}
          /> : 
          <Typography style={{color:"#fff",fontWeight:"bold",textAlign:"center"}}>
              {title}
          </Typography>
        }
      </button>
    </Fragment>
  )
}
export default IconButton;
