import React, {Fragment} from "react";
import {Row,Col,Container} from "react-bootstrap";
import {FaCircle,FaCheckCircle} from "react-icons/fa";
import { BACKEND, colors } from "../../../../../../../Utils/Constants";
import { useConfirmation } from "../Store";
const Terminos123 = ()=>{
    const {
        terminos1,setTerminos1,
        terminos2,setTerminos2,
        terminos3,setTerminos3,
    } = useConfirmation();
    const spanStyle = {
        color:colors.gray,
        fontSize:12,
        fontWeight:"bold",
        textAlign:"center"
    }
    return(
        <Fragment>
            <div className="d-flex justify-content-center">
                <span style={spanStyle}>
                    Para continuar es necesario aceptar las siguientes políticas.
                </span>
            </div>
            <Container>
                <Row style={{padding:10,borderStyle:"solid",borderColor:colors.bgDark,borderRadius:10}}>
                    <Col xs="5">
                        <div className="d-flex justify-content-end">
                            {
                                terminos1 ? 
                                <FaCheckCircle onClick={()=>setTerminos1(!terminos1)} style={{fontSize:15,color:colors.secondary,cursor:"pointer"}} /> 
                                :
                                <FaCircle onClick={()=>setTerminos1(!terminos1)} style={{fontSize:15,color:colors.bgDark,cursor:"pointer"}} />
                            }
                        </div>
                    </Col>
                    <Col xs="7">
                        <div className="d-flex justify-content-start">
                            <a target="_blank" rel="noreferrer" href={`${BACKEND}terminos`} style={{fontWeight:"bold",fontSize:10,color:terminos1?colors.secondary:"dimgrey"}} className="alineado">
                                Aviso de privacidad.
                            </a>
                        </div>
                    </Col>

                    <Col xs="5">
                        <div className="d-flex justify-content-end">
                            {
                                terminos2 ? 
                                <FaCheckCircle onClick={()=>setTerminos2(!terminos2)} style={{fontSize:15,color:colors.secondary,cursor:"pointer"}} /> 
                                :
                                <FaCircle onClick={()=>setTerminos2(!terminos2)} style={{fontSize:15,color:colors.bgDark,cursor:"pointer"}} />
                            }
                        </div>
                    </Col>
                    <Col xs="7">
                        <div className="d-flex justify-content-start">
                            <a target="_blank" rel="noreferrer" href={`${BACKEND}terminos_condiciones`} style={{fontWeight:"bold",fontSize:10,color:terminos2?colors.secondary:"dimgrey"}} className="alineado">
                                Términos y condiciones.
                            </a>
                        </div>
                    </Col>

                    <Col xs="5">
                        <div className="d-flex justify-content-end">
                            {
                                terminos3 ? 
                                <FaCheckCircle onClick={()=>setTerminos3(!terminos3)} style={{fontSize:15,color:colors.secondary,cursor:"pointer"}} /> 
                                :
                                <FaCircle onClick={()=>setTerminos3(!terminos3)} style={{fontSize:15,color:colors.bgDark,cursor:"pointer"}} />
                            }
                        </div>
                    </Col>
                    <Col xs="7">
                        <div className="d-flex justify-content-start">
                            <a target="_blank" rel="noreferrer" href={`${BACKEND}devolucion`} style={{fontWeight:"bold",fontSize:10,color:terminos3?colors.secondary:"dimgrey"}} className="alineado">
                                Políticas de devolución.
                            </a>
                        </div>
                    </Col>
                    {/*<Col xs="12" >
                        <div className="d-flex justify-content-center mt-2">
                            <IconButton icon="FaMoneyBill" type="info" loader={loaderPayment} execute={execute} title="Proceder al pago" round disabled={checkEstados()}/>
                        </div>
                    </Col>*/}
                </Row>
            </Container>
        </Fragment>   
    )
}
export default Terminos123;