import Load from "../../Components/Elements/AloneLoader";
import ErrorContainer from "../../Components/Elements/ErrorContainer";
import Header from "../../Components/Elements/Header";
import ContentConfig from "../../Components/Elements/LeftBar/UserData/ModalContentConfig";
import { useUser } from "../../Context/User";
import { MyConfigProvider, useMyConfig } from "./store";

const MyConfig = ()=>(
    <MyConfigProvider>
        <div style={{width:"100%"}}>
            <Header />
            <MyConfigTunnel />
            <div style={{height:150}}></div>
        </div>
    </MyConfigProvider>
)
export default MyConfig;
const MyConfigTunnel = ()=>{
    const {user} = useUser();
    const {configData,configLoad,configError} = useMyConfig();
    if(user){
        if(configLoad)return <Load isVisible={true} />
        if(configData)return <ContentConfig />
        if(configError)return <ErrorContainer err={configError} />
        return null;
    }else{
        return <ErrorContainer err={"Debes iniciar sesión para visualizar este contenido."} />
    }
}