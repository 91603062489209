import React from "react";
import { LoginContextProvider, useLoginContext } from "./store";
import ErrorContainer from "../../ErrorContainer";
import Load from "../../AloneLoader";
import RegenForm from "./RegenForm";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";
const LoginContent = (props)=>(<LoginContextProvider {...props}>
    <LoginTunnel />
</LoginContextProvider>);
const LoginTunnel = ()=>{
    const {LoginError,LoginData,LoginDataLoader} = useLoginContext();
    //console.log("DATA EN TUNNEL",{LoginError,LoginData,LoginDataLoader});
    if(LoginDataLoader)return <Load isVisible={true} />;
    if(LoginData)return <LoginContainer />;
    if(LoginError)return <ErrorContainer error={LoginError} />;
    return(
        <span style={{color:"#fff"}}>
                CLICKME
        </span>
    )
}
const LoginContainer = ()=>{
    const {registerUser} = useLoginContext();
    //console.log("LoginContainer",registerUser)
    switch (registerUser) {
        case "regen":
            return <RegenForm />
        case true:
            return <RegisterForm />
        default:
            return <LoginForm />
    }
}
export default LoginContent;