import React from "react";
import Load from "../../../Components/Elements/AloneLoader";
import ErrorContainer from "../../../Components/Elements/ErrorContainer";
import { useScreen } from "../../../Context/Screen/store";
import CheckAgain from "./CheckAgain";
import Content from "./Content";
import Declined from "./Declined";
import { TicketsViewerProvider, useTicketsViewer } from "./Store";
const TicketsViewer = ()=>{
    const {screenData} = useScreen();
    const {status} = screenData;
    const {status_id,id_preference,id_intent} = status;
    
    if(status_id===1){
        return <CheckAgain />
    }
    if(status_id===3){
        return <Declined />
    }
    return(
        <TicketsViewerProvider id_preference={id_preference} id_intent={id_intent}>
            <TicketsViewerTunnel />
        </TicketsViewerProvider>
    )
}
export default TicketsViewer;

const TicketsViewerTunnel = ()=>{
    const {ticketsData,ticketsLoad,ticketsError} = useTicketsViewer();
    if(ticketsLoad){
        return <Load isVisible={true} />
    }
    if(ticketsError){
        return <ErrorContainer error={ticketsError} style={{marginTop:10}} />
    }
    if(ticketsData){
        return <Content />
    }
    return null;
}