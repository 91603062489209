import React from "react";
import {Fade} from "@mui/material";
import Content from "./Content";
import {colors} from "../../../../../Utils/Constants";

const SeatSelector = ()=>{
    return(
        <>
            <Fade in={true} timeout={800}>
                <div>
                    <div className="d-flex justify-content-center">
                        <span style={{color:colors.white,fontWeight:"bold",fontSize:15}}>
                                Número de asientos
                        </span>  
                    </div>
                    <Content />
                </div>
            </Fade>
        </>
    )
}
export default SeatSelector;