import {FadeIn} from "react-slide-fade-in";
import Body from "../../../../../Components/Elements/RightBar/Body";
import { useUser } from "../../../../../Context/User";
import Modal from '@mui/material/Modal';
import "./styles.css"
const ModalFunctions = ()=>{
    const {selectedFunction,resetValues} = useUser();
    const open = selectedFunction ? true : false;
    if(selectedFunction){
        return(
            <Modal
                open={open}
                onClose={resetValues}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <FadeIn
                    from="bottom"
                    positionOffset={400}
                    triggerOffset={200}
                    delayInMilliseconds={0}
                >
                    <div className="Modal-Functions">
                        <Body />
                    </div>
                </FadeIn>
            </Modal>
        )
    }
    return null;
}
export default ModalFunctions;