import React,{useRef} from "react";
import {colors} from "../../Utils/Constants";

const Input = (props) => {
  const p = props;
  const {errorMsg,labelColor,label} = props;
  const target = useRef(null);
  const InputStyles = {
    width:(p.wdt)?p.wdt:"100%",
    borderColor:colors.gray,
    marginTop:(p.marginTop)?p.marginTop:0,
    color:colors.gray,
    cursor:p.disabled?"no-drop":"auto",
    fontWeight:"bold"
  }
  return(
    <>
      {
        label?
        <label className="badge" style={{color:labelColor||"#fff"}}>
          {label}
        </label> : null
      }
      <input
        ref={target}
        type={(p.password)?"password":"text"}
        className={(p.cls)?"input"+p.cls:"input"}
        placeholder={(p.placeholder)?p.placeholder:""}
        style={InputStyles}
        value={(p.value)?p.value:""}
        onChange={(p.onChange)?(e)=>p.onChange(e.target.value,(p.index!==undefined)?p.index:null):(e)=>console.log("value cambio a:",e.target.value)}
        disabled={(props.disabled)?true:false}
      />
      {
        errorMsg?
        <div className="d-flex justify-content-center">
          <span style={{fontSize:10,textAlign:"center",fontWeight:"bold",color:colors.gray}}>
            {errorMsg}
          </span>
        </div> : null
      }
    </>
  )
};
export default (Input);