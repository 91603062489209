
const PKSTRING = {
    PKSTRIPE_PROD: 'pk_live_51IlLS6LO8EvrqqDZ4TnS99242RvpmRPoGWG4MIDvcirq2lzMDKszvxNj0y1N821Ko48Z7MFMKXXaUtVzfinnTW3P00KWWLQ0KT',
    PKSTRIPE_DEV: 'pk_test_gLGU398IN6dfa93LBSOzPunL00so3QuDJO'
}
const ISDEV = process.env.REACT_APP_DEV  === true || process.env.REACT_APP_LOCAL;
export const getPKStripe = ()=>{
    if(ISDEV){
        return PKSTRING.PKSTRIPE_DEV;
    }else{
        return PKSTRING.PKSTRIPE_PROD;
    }
}
