import React, { useState } from "react";
import { Card, Container } from "react-bootstrap";
import { colors } from "../../../../../../../Utils/Constants";
import IconButton from "../../../../../IconButton";
import { useConfirmation } from "../Store";

const Terminos4 = ()=>{
    const {
        setTerminos4
    } = useConfirmation();
    const [load,setLoad] = useState(false);
    const OnClickButton = ()=>{
        setLoad(true);
        setTimeout(()=>{
            setTerminos4(true);
            setLoad(false);
        },1000);
    }
    return(
        <Container className="text-center">
            <div
                className="p-3"
                style={{
                    width:"100%"
                }}
            >
                <Card className="p-3" style={{
                    backgroundColor:colors.gray
                }}>
                    <div
                        className="text-justify"
                        style={{
                            width:"100%",
                            maxHeight:100,
                            overflowY: "scroll",
                            color:colors.bgGrayDark,
                            fontWeight:"bold",
                            fontSize:10
                        }}
                    >   
                        Al cargar una foto de su identificación o formato similar, o información requerida por nosotros , usted acepta de forma tácita nuestros términos y condiciones conforme a los artículos 3 fracción I, 6, 8, 17, 26 fracción I, 34 y 37 fracciones IV y VII de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP) y los artículos 89, 89 bis y 90 del Código de Comercio. Usted asume plena responsabilidad por la autenticidad y el uso adecuado de la información proporcionada.

                        Dreamshows/John milton se deslinda de toda responsabilidad en caso de mal uso de sus datos o cualquier delito que pueda surgir como resultado, sea este cibernético o de otra índole. Cualquier transacción realizada en base a esta información será considerada como válida, fidedigna y firme. Nos reservamos el derecho a toda devolución; no se aceptará reclamación sobre devolución ni cambios de ningún tipo.

                        No asumimos responsabilidad sobre la veracidad de la información; esa es responsabilidad del usuario. No somos una entidad que certifique la autenticidad de la información proporcionada. Somos exclusivamente una tienda virtual y solo ofrecemos lo que en ella aparece. Si por fallas de pérdida de datos, por doble compra o por actualizar la página se hace un cargo adicional o duplicado, será responsabilidad de quien compra y se sujetará a las condiciones de su institución bancaria o a quien le corresponda.

                        Al utilizar esta tienda virtual, usted reconoce que ha leído y comprendido todos los términos y condiciones, y se compromete a respetarlos en su totalidad. Dreamshows/ John milton se reserva el derecho de modificar los términos y condiciones en cualquier momento, y el uso continuado de la tienda virtual tras dichas modificaciones constituirá su acuerdo con los nuevos términos. Dreamshows/john milton no garantiza la disponibilidad ininterrumpida de la tienda virtual y no se hace responsable por cualquier daño directo o indirecto que pueda resultar del uso o la imposibilidad de uso de la tienda.

                        Su acuse de recibo para cualquier transacción o interacción con esta tienda virtual será una captura de pantalla que certifique la operación realizada. Los usuarios aceptan que todas las transacciones son finales y no sujetas a reembolso, excepto en los casos expresamente señalados por la política de devoluciones de Dreamshows/John milton Toda disputa relacionada con la tienda virtual deberá ser resuelta según la legislación vigente en la jurisdicción correspondiente.
                    </div>
                </Card>
                <div className="d-flex justify-content-center mt-3">
                    <IconButton 
                        execute={OnClickButton}
                        loader={load}
                        title="Estoy de acuerdo"
                    />
                </div>
            </div>
        </Container>
    )
}
export default Terminos4;