import React from "react";
import ListHorizontal from "./ListHorizontal";
import { colors } from "../../../../../Utils/Constants";
import {FaRegSadCry,FaHandPointUp} from "react-icons/fa";
import { useAreaSelector } from "./Context";
import {useUser} from "../../../../../Context/User";

const Content = ()=>{
    const {areas} = useAreaSelector();
    const {selectedArea} = useUser();
    const errorTagStyle = {
        padding:20,
        borderRadius:15,
        borderStyle:"solid",
        borderWidth:2,
        borderColor:colors.bgGrayDark,
        width:"100%",
        marginTop:10
    }
    return(
        <>
            {
                areas.length>0? 
                <>
                    <div className="d-flex justify-content-center">
                        <span style={{color:colors.white,fontWeight:"bold",fontSize:15}}>
                            Seleccionar Área
                        </span>  
                    </div>
                    <ListHorizontal />
                    {
                        !selectedArea ? 
                            <>
                                <div style={errorTagStyle} className="d-flex justify-content-center">
                                    <div style={{display:"flex",flexDirection:"row",maxWidth:300}}>
                                        <div style={{width:"30%"}} className="alineado">
                                            {
                                                <FaHandPointUp style={{fontSize:60,color:colors.white}} />
                                            }
                                        </div>
                                        <div style={{width:"70%",textAlign:"center"}}>
                                            <span style={{fontWeight:"bold",fontSize:20,color:"dimgrey"}} className="alineado">
                                                Selecciona el área de tus asientos.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </> : null 
                    }
                </> : 
                <div style={errorTagStyle} className="d-flex justify-content-center">
                    <div style={{display:"flex",flexDirection:"row",maxWidth:300}}>
                        <div style={{width:"30%"}} className="alineado">
                            {
                                <FaRegSadCry style={{fontSize:60,color:colors.secondary}} />
                            }
                        </div>
                        <div style={{width:"70%",textAlign:"center"}}>
                            <span style={{fontWeight:"bold",fontSize:20,color:"dimgrey"}} className="alineado">
                                Al parecer no hay áreas configuradas en este evento
                            </span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Content;