
const FRONTEND = {
    LOCAL: "http://localhost:8082/",
    REMOTE: "https://duermase.net/",
    REMOTE_DEV: "https://develop.duermase.net/"
}   
const DEV = process.env.REACT_APP_DEV === true;
const LOCAL = process.env.REACT_APP_LOCAL;
export const GetFrontend = ()=>{
    if(DEV&&!LOCAL){
        return FRONTEND.REMOTE_DEV;
    }else if(DEV&&LOCAL){
        return FRONTEND.LOCAL;
    }else{
        return FRONTEND.REMOTE;
    }
}
