import ErrorContainer from "../../../Components/Elements/ErrorContainer";
import Load from "../../../Components/Elements/Load";
import { HomeContextProvider, useHomeContext } from "./store";
import Body from "./Body";

const Content = ()=>(<HomeContextProvider production={false}>
    <ContentTunnel />
</HomeContextProvider>);
const ContentTunnel = ()=>{
    const {HomeData,HomeLoader,HomeError} = useHomeContext();
    if(HomeLoader)return <Load isVisible={true} />
    if(HomeData)return <ContentContainer />
    if(HomeError)return <ErrorContainer err={HomeError} />
    return null;
};
const ContentContainer = ()=>(
    <div style={{width:"100%"}}>
        <Body />
    </div>
);
export default Content;