/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment,useState,useRef} from "react";
import { BACKEND, colors } from "../../../../../../Utils/Constants";
import { Toast } from "../../../../../../Utils/Toast";
import axios from "axios";
import { useUser } from "../../../../../../Context/User";
import { useConfirmation } from "./Store";
import { Container } from "react-bootstrap";

const ImageSelector = ()=>{
    const [file] = useState(null);
    const inputFileRef = useRef(null);
    const {user} = useUser();
    const {refreshNeedImg} = useConfirmation();

    const onChangeFile = async(e)=>{
        if(inputFileRef.current.files[0]){
        Toast("Subiendo Imágen..","info");
          let formData = new FormData();
          formData.append("userIne", inputFileRef.current.files[0]);
          formData.append("user",user.id_usuario);
    
          let r = await axios.post(BACKEND+"insertUserIne",formData,{'content-type': 'multipart/form-data',}).catch(e=>{
              //console.log(e);
              Toast("Error al conectar al servidor.","error");
          })
          if(r){
            if(r.data.error){
              Toast(r.data.error,"error");
            }else{
              Toast("Imágen actualizada exitosamente. Por favor acepte los terminos y condiciones para continuar","success")
              refreshNeedImg();
            }
          }
        }else{
          Toast("Error formato de imágen inválido.","error");
        }
      }
      const openInput = ()=>{
        inputFileRef.current.click()
      }
    return(
        <Fragment>
            <input type="file" accept="image/*" value={file} onChange={onChangeFile} ref={inputFileRef} style={{display:"none"}} />
            <Container className="d-flex justify-content-center">
              {
                  !file ?
                  <img 
                      src={process.env.PUBLIC_URL + '/img/INE_MILTON.png?param=' + Math.random()}
                      onClick={openInput}
                      style={{
                          width:"100%",
                          cursor:"pointer",
                          maxWidth: 400
                      }}
                      alt="INE"
                  /> : null
              }
            </Container>
            <div className="d-flex justify-content-center">
                <span style={{color:colors.secondary,fontSize:15,fontWeight:"bold",textAlign:"justify"}}>
                    Por seguridad y para evitar cargos fraudulentos, estamos solicitando tomes una fotografía de la credencial de elector del comprador, muchas gracias por su comprención.
                </span>
            </div>
        </Fragment>
    )
}
export default ImageSelector; //https://meet.google.com/jxh-rjjq-eef