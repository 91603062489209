/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState,useMemo,useEffect,useRef} from "react";
import {colors, REQUEST} from "../../Utils/Constants";
import {Toast} from "../../Utils/Toast";
const colors1 = colors;

const UserContext = React.createContext();

export const UserProvider = (props)=>{
  // controlled states
  const [user,setUser] = useState(null);
  const ModalFunctionsRef = useRef(null);
  const [selectedFunction,setSelectedFunc] = useState(null);
  const [selectedDate,setSelectedDate] = useState(null);
  const [selectedArea,setSelectedArea] = useState(null);
  const [selectedPrice,setSelectedPrice] = useState(null);
  const [loadingUser,setLoadingUser] = useState(false);
  const [colors] = useState(colors1);
  const [seats,setSeats] = useState(0);
  const [tickets,setTickets] = useState([]);
  const [loadingTickets,setLoadingTickets] = useState(false);
  const [intents,setIntents] = useState(0);
  const [oldRows,setOldRows] = useState([]);
  const addIntent = ()=>setIntents(intents+1);
  const handleToggleLoadTickets = (param)=>setLoadingTickets(param);
  const handleToggleLoadingUser = (param)=>setLoadingUser(param);
  //left bar state (Mobile)
  const [isOpenedLeftBar,setIsOpenedLeftBar] = useState(false);
  const handleLeftBar = (param)=>setIsOpenedLeftBar(param);
  //modal states
  const [modalLogin,setModalLogin] = useState(false);
  const handleToogleModalLogin = (param)=>setModalLogin(param);
  const [modalRegister,setModalRegister] = useState(false);
  const [modalConfig,setModalConfig] = useState(false);
  const [modalMyShopping,setModalShopping] = useState(false);
  const handleToogleModalMyShopping = (param)=>setModalShopping(param);
  
  const [menuListOption,setMenuListOption] = React.useState(false);
  const isOpenedMenuList = Boolean(menuListOption);
  const handleClickMenuList = (event)=>setMenuListOption(event.currentTarget);
  const [defaultModalConfigIndex,setDefaultModalConfigIndex] = useState(0);
  const handleChangeDefaultIndex = (param)=>setDefaultModalConfigIndex(param);
  const handleToggleModalConfig = ({state,defaultIndex})=>{
    if(state!=="undefined"){
      setModalConfig(state);
    }
    if(defaultIndex){
      handleChangeDefaultIndex(defaultIndex);
    }
  }
  const resetValues = ()=>{
    setSelectedFunc(null);
    setSelectedDate(null);
    setSeats(0);
    setSelectedArea(null);
    setSelectedPrice(null);
    setTickets([])
  }
  const handleToogleModalRegister = {
    show:()=>setModalRegister(true),
    hide:()=>setModalRegister(false)
  }
  const setUserData = ({data,msg})=>{
    if(data){
      setUser(data);
      Toast(msg||`Bienvenido ${data.first_name}`,"info");
    }else{
      Toast("Error datos incorrectos.","error");
    }
  }
  const Seats = {
    addSeat:(n)=>{
      if(!isNaN(n)){
        setSeats(seats+n)
      }
    },
    subtractSeat:(n)=>{
      if(!isNaN(n)){
        if(seats-n>=0){
          setSeats(seats-n);
        }
      }
    }
  }
  const modifyFunction = (element)=>{
    //console.log("ELEMENT",element);
    setSelectedFunc(element);
    setSelectedDate(null);
    setSeats(0);
    setSelectedArea(null);
    setSelectedPrice(null);
    setTickets([])
    setOldRows([])
    setIntents(0);
    handleLeftBar(false);
  }
  const modifyDate = (element)=>{
    setSelectedDate(element);
    setSeats(0);
    setSelectedArea(null);
    setSelectedPrice(null);
    setTickets([])
    setOldRows([])
    setIntents(0);
  }
  const modifyArea = (element)=>{
    setSelectedArea(element);
    setSelectedPrice(null);
    setTickets([])
    setOldRows([])
    setIntents(0);
  }
  const modifyPrice = (element)=>{
    setSelectedPrice(element)
    setTickets([])
    setOldRows([])
    setIntents(0);
  }
  const logout = async()=>{
    let r = await REQUEST({
      url:"",
      postData:{
        type:"logout"
      }
    }).catch(e=>{
      Toast("Error al cerrar sesión","error")
    })
    if(r){
      Toast("Hasta pronto.","info");
      setUser(null);
      setTimeout(()=>{
        window.location.href="/";
      },1500)
    }
  }
  const getUserData = async()=>{
    let r = await REQUEST({
      url:"",
      postData:{
        type:"getUserData",
      },
      handleToggleLoad:handleToggleLoadingUser
    }).catch(e=>{
      Toast("Error al conectar al servidor","error")
    })
    if(r){
      if(r.error){
        //Toast(r.error,"error");
      }else{
          setUserData({
              data:r.result.userData,
              msg:`Sesión recuperada, Bienvenido ${r.result.userData.first_name}`
          })
      }
    }
  }
  const searchTickets = async(elRef)=>{
    if(user&&user!=="Invitado"){
      addIntent()
      let r = await REQUEST({
        url:"",
        postData:{
          type:"searchTickets",
          user,
          data:{
            event:selectedFunction.id_clave,
            func:selectedDate,
            area:selectedArea,
            price:selectedPrice,
            n_seats:seats,
            intents,
            oldRows
          }
        },
        handleToggleLoad:handleToggleLoadTickets
      }).catch(e=>{
        Toast("Error al conectar al servidor","error")
      })
      if(r){
        if(r.error){
          Toast(r.error,"error");
        }else{
          setTickets(r.result.tickets);
          if(r.result.tickets.length>0){
            let newRows = oldRows.filter(item=>item);
            for (let i = 0; i < r.result.tickets.length; i++) {
              const ticket = r.result.tickets[i];
              newRows.push(ticket);
            }
            setOldRows(newRows.concat());
            //elRef.current.scrollIntoView();
          }
        }
      }
    }
  }
  useEffect(()=>{
    if(!user){
      getUserData();
    }
  },[user])
  const value = useMemo(()=>{
    return({
      user,logout,setUserData,
      loadingUser,Toast,colors,selectedFunction,
      modifyFunction,isOpenedLeftBar,
      handleLeftBar,selectedDate,
      modifyDate,seats,Seats,
      modalLogin,handleToogleModalLogin,
      modalRegister,handleToogleModalRegister,modalMyShopping,handleToogleModalMyShopping,
      selectedArea,modifyArea,
      selectedPrice,modifyPrice,
      tickets,searchTickets,loadingTickets,
      modalConfig,handleToggleModalConfig,defaultModalConfigIndex,
      resetValues,oldRows,setOldRows,ModalFunctionsRef,
      isOpenedMenuList,handleClickMenuList,setMenuListOption,
    })
  },[
    user,loadingUser,selectedFunction,
    isOpenedLeftBar,selectedDate,seats,
    modalLogin,selectedArea,selectedPrice,
    tickets,loadingTickets,modalConfig,
    defaultModalConfigIndex,oldRows,modalMyShopping,
    ModalFunctionsRef,isOpenedMenuList,
  ])
  return <UserContext.Provider value={value} {...props}/>
}


export const useUser = ()=>{
  return React.useContext(UserContext);
}
