import React, {Fragment} from "react";
import { colors } from "../../../Utils/Constants";
import { useTicketsViewer } from "./Store";
import QRCode from "react-qr-code";
import { Col, Row } from "react-bootstrap";
import { FaTheaterMasks } from 'react-icons/fa';
import ErrorContainer from "../../../Components/Elements/ErrorContainer";
import {MdError} from "react-icons/md";
import IconButton from "../../../Components/Elements/IconButton";
const Content = ()=>{
    const {ticketsData} = useTicketsViewer();
    const {order,oldTickets,newTickets} = ticketsData; 
    if(order){
        return(
            <Fragment>
                <OrderViewer />
                <TicketsViewer />
            </Fragment>
        )
    }
    if(oldTickets&&newTickets && oldTickets.length === newTickets.length){
        return(
            <Fragment>
                <AcceptNewTickets />
            </Fragment>
        )
    }
    return null;
}
const AcceptNewTickets = ()=>{
    const {ticketsData, acceptNewTickets, newTicketsLoad} = useTicketsViewer();
    const {oldTickets,newTickets} = ticketsData; 
    const titleContainerStyle = {
        display:"flex",
        width:"100%",
        justifyContent:"center",
        alignItems:"center",
        color:colors.white,
        fontWeight:"bold"
    }
    const leftTextStyle = {
        color:colors.white,
        fontWeight:"bold"
    }
    const rightTextStyle = {
        color:colors.white,
        fontWeight:"bold",
        textAlign:"right"
    }
    const OnClickAccept = ()=>acceptNewTickets({
        oldTickets,
        newTickets
    });
    return(
        <Fragment>
            <div
                style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                }}
            >
                <MdError color={colors.secondary} style={{
                    fontSize:50
                }} />
            </div>
            <span
                style={{
                    fontWeight:"bold",
                    textAlign:"center",
                    color:"#fff",
                    fontSize:18
                }}
            >
                Sentimos informarte que al menos un asiento de tu compra no se encuentra disponible. <br />
                Te ofrecemos este cambio en la misma zona y precio. <br />
                Equipo John Milton
            </span>
            <div className="row mt-3">
                <div className="col-sm-12 col-md-6">
                    <div style={{
                        backgroundColor:colors.bgGrayDark,
                        padding:20,
                        width:"100%"
                    }}>
                        <div
                            style={titleContainerStyle}
                        >
                            Asientos Anteriores
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p style={leftTextStyle}>
                                    Número de boletos:
                                </p>
                                <p style={leftTextStyle}>
                                    Fila:
                                </p>
                                <p style={leftTextStyle}>
                                    {oldTickets.length>1?"Butacas:":"Butaca:"}
                                </p>
                            </div>
                            <div className="col-6">
                                <p style={rightTextStyle}>
                                    {oldTickets.length}
                                </p>
                                <p style={rightTextStyle}>
                                    {oldTickets[0].rowName}
                                </p>
                                <p style={rightTextStyle}>
                                    {
                                        oldTickets.map((ticket,ticketIndex)=>(
                                            <span
                                                key={ticketIndex}
                                                style={{
                                                    marginLeft: ticketIndex === 0 ? 0 : 15
                                                }}
                                            >
                                                {ticket.butaca} {ticketIndex+1 < oldTickets.length ? "/" : null}
                                            </span>
                                        ))
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div style={{
                        backgroundColor:colors.bgGrayDark,
                        padding:20,
                        width:"100%"
                    }}>
                        <div style={titleContainerStyle}>
                            Asientos Nuevos
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p style={leftTextStyle}>
                                    Número de boletos:
                                </p>
                                <p style={leftTextStyle}>
                                    Fila:
                                </p>
                                <p style={leftTextStyle}>
                                    {newTickets.length>1?"Butacas:":"Butaca:"}
                                </p>
                            </div>
                            <div className="col-6">
                                <p style={rightTextStyle}>
                                    {newTickets.length}
                                </p>
                                <p style={rightTextStyle}>
                                    {newTickets[0].rowName}
                                </p>
                                <p style={rightTextStyle}>
                                    {
                                        newTickets.map((ticket,ticketIndex)=>(
                                            <span
                                                key={ticketIndex}
                                                style={{
                                                    marginLeft: ticketIndex === 0 ? 0 : 15
                                                }}
                                            >
                                                {ticket.butaca} {ticketIndex+1 < newTickets.length ? "/" : null}
                                            </span>
                                        ))
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div style={{
                        display:"flex",
                        width:"100%",
                        justifyContent:"center",
                        alignItems:"center"
                    }}>
                        <IconButton 
                            type="info"
                            title="Aceptar Cambio"
                            execute={OnClickAccept}
                            loader={newTicketsLoad}
                        />   
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
const OrderViewer = ()=>{
    const {ticketsData} = useTicketsViewer();
    const {order} = ticketsData;
    const getFullDate = (f)=>{
        return `${getStringDate(f)} de ${getStringMonth(f)} del ${getStringYear(f)}`;
    }
    const getStringDate = (f)=>{
        let f_edited = f.replace("T"," ").replace("Z","");
        f_edited = f_edited.replaceAll("-","/")
        if(f_edited.length>19){
            f_edited = f_edited.substring(0,(f_edited.length-4));
        }
        let newDate = new Date(f_edited);
        let day = newDate.getDate();
        return day;
    }
    const getStringMonth = (f)=>{
        let f_edited = f.replace("T"," ").replace("Z","");
        f_edited = f_edited.replaceAll("-","/")
        if(f_edited.length>19){
            f_edited = f_edited.substring(0,(f_edited.length-4));
        }
        let newDate = new Date(f_edited);
        let index = newDate.getMonth();
        let months = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
        return months[index];
    }
    const getStringYear = (f)=>{
        let f_edited = f.replace("T"," ").replace("Z","");
        f_edited = f_edited.replaceAll("-","/")
        if(f_edited.length>19){
            f_edited = f_edited.substring(0,(f_edited.length-4));
        }
        let newDate = new Date(f_edited);
        let year = newDate.getFullYear();
        return year.toString();;
    }
    return(
        <Fragment>
            <Row>
                <Col xs="12">
                    <div className="d-flex justify-content-center">
                        <span style={{color:colors.white}}>
                            CÓDIGO QR
                        </span>
                    </div>
                    {
                        order.folio?
                        <Fragment>
                            <div className="p-2 d-flex justify-content-center">
                                <QRCode value={order.folio.toString()} size={200} bgColor={colors.white} fgColor={colors.secondary} level="H" />   
                            </div>
                            <p style={{fontSize:15,fontWeight:"bold",textAlign:"center",color:colors.gray}}>
                                Presenta tu código QR en la entrada del teatro para acceder al evento.
                            </p>
                        </Fragment> : null
                    }
                </Col>
                <Col xs="12" xl="6">
                    <div className="d-flex justify-content-center">
                        <span style={{color:colors.white}}>
                            FECHA DE FUNCIÓN
                        </span>
                    </div>
                    <div style={{backgroundColor:colors.bgDark,width:"100%",padding:20,borderRadius:15}}>
                        <div className="d-flex justify-content-center">                                            
                            <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                {
                                    getFullDate(order.fechaFuncion) + ` <${order.horaFuncion}>`
                                }
                            </span>
                        </div>
                    </div>
                </Col>
                <Col xs="12" xl="6">
                    <div className="d-flex justify-content-center">
                        <span style={{color:colors.white}}>
                            FECHA DE COMPRA
                        </span>
                    </div>
                    <div style={{backgroundColor:colors.bgDark,width:"100%",padding:20,borderRadius:15}}>
                        <div className="d-flex justify-content-center">                                            
                            <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                {getFullDate(order.fecha)}
                            </span>
                        </div>
                    </div>
                </Col>
                <Col xs="12">
                    <div className="d-flex justify-content-center">
                        <span style={{color:colors.white}}>
                            TEATRO
                        </span>
                    </div>
                </Col>
                <Col xs="12" style={{marginTop:10}}>
                    <div style={{backgroundColor:colors.bgDark,width:"100%",padding:20,borderRadius:15}}>
                        <div className="d-flex justify-content-center">  
                            <FaTheaterMasks style={{fontSize:50,color:"#fff"}} />
                        </div>
                        <div className="d-flex justify-content-center">                                            
                            <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                {order.teatro}
                            </span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}
const TicketsViewer = ()=>{
    const {ticketsData} = useTicketsViewer();
    const {order} = ticketsData;
    const {tickets} = order;
    if(tickets&&tickets.length>0){
        return(
            <Fragment>
                <Row>
                    <Col xs="12">
                        <div className="d-flex justify-content-center">
                            <span style={{color:colors.white}}>
                                ASIENTOS ({order.tickets.length})
                            </span>
                        </div>
                    </Col>
                    {
                        tickets.map((item,index)=>(
                            <Col key={index} xs="12" md="6" style={{marginTop:10}}>
                                <div style={{backgroundColor:colors.bgDark,width:"100%",padding:20,borderRadius:15}}>
                                    <div className="d-flex justify-content-center">                                            
                                        <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                            {`Butaca: ${item.butaca}`}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-center">                                            
                                        <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                            {`Fila: ${item.rowName}`}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-center">                                            
                                        <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                            {`Precio: $${item.valor.toFixed(2)}`}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Fragment>
        )
    }else{
        return <ErrorContainer error="Lo sentimos no hemos podido identificar sus asientos." />
    }
}
export default Content;