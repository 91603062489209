import React, {Fragment,useState} from "react";
import {Row,Col,Container} from "react-bootstrap";
import IconButton from "../../../IconButton";
import { colors } from "../../../../../Utils/Constants";
import { FaTheaterMasks } from 'react-icons/fa';
import QRCode from "react-qr-code";
import { useMyShopping } from "../../../../../Screens/MyShopping/store";

const Content = ()=>{
    const {configData} = useMyShopping();
    const {compras} = configData;
    const [selectedCompra,setSelectedCompra] = useState(0);
    const navPrev = ()=>{
        setSelectedCompra(selectedCompra-1);
    }
    const navNext = ()=>{
        setSelectedCompra(selectedCompra+1);
    }
    const getStringDate = (f)=>{
        let f_edited = f.replace("T"," ").replace("Z","");
        f_edited = f_edited.replaceAll("-","/")
        if(f_edited.length>19){
            f_edited = f_edited.substring(0,(f_edited.length-4));
        }
        let newDate = new Date(f_edited);
        let day = newDate.getDate();
        return day;
    }
    const getStringMonth = (f)=>{
        let f_edited = f.replace("T"," ").replace("Z","");
        f_edited = f_edited.replaceAll("-","/")
        if(f_edited.length>19){
            f_edited = f_edited.substring(0,(f_edited.length-4));
        }
        let newDate = new Date(f_edited);
        let index = newDate.getMonth();
        let months = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
        return months[index];
    }
    const getStringYear = (f)=>{
        let f_edited = f.replace("T"," ").replace("Z","");
        f_edited = f_edited.replaceAll("-","/")
        if(f_edited.length>19){
            f_edited = f_edited.substring(0,(f_edited.length-4));
        }
        let newDate = new Date(f_edited);
        let year = newDate.getFullYear();
        return year.toString();;
    }
    const getFullDate = (f)=>{
        return `${getStringDate(f)} de ${getStringMonth(f)} del ${getStringYear(f)}`;
    }
    return(
        <Fragment>
            <Container style={{overflowY:"auto"}}>
                {
                    compras.length > 0 ? 
                    <Fragment>
                        <Row>
                            <Col xs="6">
                                <div className="d-flex justify-content-center">
                                    <IconButton type="info" title="Anterior" execute={navPrev} icon="FaRegArrowAltCircleLeft" disabled={selectedCompra===0?true:false} round />
                                </div>
                            </Col>
                            <Col xs="6">
                                <div className="d-flex justify-content-center">
                                    <IconButton type="info" title="Siguiente" execute={navNext} icon="FaRegArrowAltCircleRight" disabled={selectedCompra===compras.length-1?true:false} round />
                                </div>
                            </Col>                
                        </Row>
                        <Row>
                            <Col xs="12">
                                <div className="d-flex justify-content-center">
                                    <span style={{color:colors.gray}}>
                                        CÓDIGO QR
                                    </span>
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="p-2 d-flex justify-content-center">
                                    <QRCode value={compras[selectedCompra].folio.toString()} size={200} bgColor={colors.white} fgColor={colors.secondary} level="H" />   
                                </div>
                                <p style={{fontSize:15,fontWeight:"bold",textAlign:"center",color:colors.white}}>
                                        Presenta tu código QR en la entrada del teatro para acceder al evento.
                                </p>       
                            </Col>
                            <Col xs="12" xl="6">
                                <div className="d-flex justify-content-center">
                                    <span style={{color:colors.gray}}>
                                        FECHA DE FUNCIÓN
                                    </span>
                                </div>
                                <div style={{backgroundColor:colors.bgGrayDark,width:"100%",padding:20,borderRadius:15}}>
                                    <div className="d-flex justify-content-center">                                            
                                        <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                            {
                                                getFullDate(compras[selectedCompra].fechaFuncion) + ` <${compras[selectedCompra].horaFuncion}>`
                                            }
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" xl="6">
                                <div className="d-flex justify-content-center">
                                    <span style={{color:colors.gray}}>
                                        FECHA DE COMPRA
                                    </span>
                                </div>
                                <div style={{backgroundColor:colors.bgGrayDark,width:"100%",padding:20,borderRadius:15}}>
                                    <div className="d-flex justify-content-center">                                            
                                        <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                            {getFullDate(compras[selectedCompra].fecha)}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="d-flex justify-content-center">
                                    <span style={{color:colors.gray}}>
                                        TEATRO
                                    </span>
                                </div>
                            </Col>
                            <Col xs="12" style={{marginTop:10}}>
                                <div style={{backgroundColor:colors.bgGrayDark,width:"100%",padding:20,borderRadius:15}}>
                                    <div className="d-flex justify-content-center">  
                                        <FaTheaterMasks style={{fontSize:50,color:"#fff"}} />
                                    </div>
                                    <div className="d-flex justify-content-center">                                            
                                        <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                            {compras[selectedCompra].teatro}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <div className="d-flex justify-content-center">
                                    <span style={{color:colors.gray}}>
                                        ASIENTOS ({compras[selectedCompra].asientos.length})
                                    </span>
                                </div>
                            </Col>
                            {
                                compras[selectedCompra].asientos.map((item,index)=>(
                                    <Col key={index} xs="12" md="6" style={{marginTop:10}}>
                                        <div style={{backgroundColor:colors.bgGrayDark,width:"100%",padding:20,borderRadius:15}}>
                                            <div className="d-flex justify-content-center">                                            
                                                <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                                    {`Butaca: ${item.butaca}`}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-center">                                            
                                                <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                                    {`Fila: ${item.rowName}`}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-center">                                            
                                                <span style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>
                                                    {`Precio: $${item.valor.toFixed(2)}`}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Fragment> : 
                    <div className="d-flex justify-content-center p-5">
                        <span style={{fontSize:20,fontWeight:"bold",color:colors.white}}>
                            Aún no tienes compras en tu cuenta.
                        </span>
                    </div>
                }

            </Container>
        </Fragment>
    )
}
export default Content;
    /*
        <Col xs="4">
            <div className="d-flex justify-content-center">
                <span style={{fontSize:18,fontWeight:"bold",marginTop:10}}>
                    {
                        getFullDate(compras[selectedCompra].fecha)
                    }
                </span>
            </div>
        </Col>
    */