import React from "react";
import {useUser} from "../../../Context/User";
import { colors, isWeb } from "../../../Utils/Constants";
import {FaHandPointUp,FaHandPointLeft} from "react-icons/fa";
import EventController from "./EventController";

const Body = (props)=>{
    const {selectedFunction} = useUser();

    const errorTagStyle = {
        width:"100%",
        padding:20,
        borderRadius:15,
        borderStyle:"solid",
        borderWidth:2,
        borderColor:colors.secondary
    }
    return(
        <div style={{padding:5,paddingBottom:20,overflowY:"auto",display:"flex",flexDirection:"column"}}>
            {
                selectedFunction ? 
                <>
                    <EventController />
                </> :
                <div style={errorTagStyle} className="d-flex justify-content-center">
                    <div style={{display:"flex",flexDirection:"row",maxWidth:300}}>
                        <div style={{width:"30%"}} className="alineado">
                            {
                                isWeb()? <FaHandPointLeft style={{fontSize:60,color:"#000"}} /> : <FaHandPointUp style={{fontSize:60,color:colors.secondary}} />
                            }
                        </div>
                        <div style={{width:"70%",textAlign:"center"}}>
                            <span style={{fontWeight:"bold",fontSize:20,color:"dimgrey"}} className="alineado">
                                {
                                    isWeb() ? "Seleccione un evento para continuar" : "Presione 'EVENTOS' para seleccionar un evento"
                                }
                            </span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Body;