/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState,useMemo,useEffect} from "react";
import {REQUEST} from "../../../../../Utils/Constants";
import {Toast} from "../../../../../Utils/Toast";
import {useUser} from "../../../../../Context/User";

const TicketsContext = React.createContext();

const get_ini_year = ()=>{
  let date = new Date();
  let year =  date.getFullYear().toString();
  return year.slice(2,4);
}
export const TicketsProvider = (props)=>{
    const [data,setData] = useState(null);
    const [CVC,setCVC] = useState("");
    const [NUMBER,setNUMBER] = useState("");
    const [CARDNAME,setCARDNAME] = useState("");
    const [EXPMONTH,setEXPMONTH] = useState("01");
    const [EXPYEAR,setEXPYEAR] = useState(get_ini_year());
    const [DIR,setDIR] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const handleLoading = (param)=>setIsLoading(param);
    const [error,setError] = useState(null);
    const {user,selectedPrice,selectedDate,tickets} = useUser();
    const getData = async()=>{
        handleLoading(true);
        let postData = {type:"getTicketsData",user:user.id_usuario,id_price:selectedPrice,id_function:selectedDate,tickets};
        let r = await REQUEST({url:"",postData}).catch(e=>{
          Toast("Error al conectar al servidor","error");
          handleLoading(false);
        })
        if(r){
          if(r.error){
            Toast(r.error,"error");
            setError(r.error);
          }else{
            setData(r.result);
          }
        }
        handleLoading(false);
    }

    useEffect(()=>{
        getData();
    },[tickets])
    const value = useMemo(()=>{
        return({
            data,
            getData,
            isLoading,
            error,
            CVC,setCVC,
            NUMBER,setNUMBER,
            CARDNAME,setCARDNAME,
            EXPMONTH,setEXPMONTH,
            EXPYEAR,setEXPYEAR,
            DIR,setDIR
        })
    },[
      data,
      CVC,
      NUMBER,
      CARDNAME,
      EXPMONTH,
      EXPYEAR,
      isLoading,
      error,
      DIR
    ])
    return <TicketsContext.Provider value={value} {...props}/>
}


export const useTickets = ()=>{
  return React.useContext(TicketsContext);
}
