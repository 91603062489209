import { Typography } from "@mui/material";
import {Container,Row,Col} from "react-bootstrap";
import Input from "../../Input";
import { useLoginContext } from "./store";
import { colors } from "../../../../Utils/Constants";
import IconButton from "../../IconButton";
const RegenForm = ()=>{
    const {email_r,setEmail_r,checkMail,recuperatePassword,load,func,checkFormErrors2} = useLoginContext();
    return(
        <Container>
            <Row>
                <Col xs="12">
                    <Typography style={{fontWeight:"bold",color:colors.gray,textAlign:"center"}}>
                        Enviaremos una contraseña temporal a tu correo electrónico la cual podrás cambiar al iniciar sesión con la misma.
                    </Typography>
                </Col>
                <Col xs="12">
                    <Input value={email_r} onChange={setEmail_r} errorMsg={checkMail(true)} placeholder="Correo electrónico" label="Ingresa tu correo electrónico" />
                </Col>
                <Col xs="12" className="d-flex justify-content-center mt-4">
                    <IconButton icon="IoLogIn" type="info" execute={recuperatePassword} loader={load} title="Regenerar contraseña" disabled={checkFormErrors2()} round />
                </Col>
                <Col xs="8" style={{marginTop:15}}>
                    <Typography
                        style={{
                            textAlign:"center",
                            color:colors.gray,
                            fontSize:12,
                            cursor:"no-drop"
                        }}
                    >
                        ¿Ya tienes una cuenta?
                    </Typography>
                </Col>
                <Col xs="4" style={{marginTop:15}}>
                    <Typography
                        onClick={func.showLogin}
                        style={{
                            textAlign:"center",
                            color:colors.secondary,
                            fontSize:12,
                            cursor:"pointer"
                        }}
                    >
                        Inicia Sesión
                    </Typography>
                </Col>
            </Row>
        </Container>
    )
}
export default RegenForm;