import React from "react";
import { colors, BACKEND } from "../../../../../Utils/Constants";
import "./styles.css";
import { useNavigate } from 'react-router-dom';
const ContentConfig = (props)=>{
    const navigate = useNavigate();
    const itemStyle = {
        color:colors.secondary,
        fontSize:22,
        fontWeight:"bold",
        cursor:"pointer",
        padding:5
    }
    const aStyle = {
        color:colors.gray
    }
    return(
        <div style={{display:"flex",width:"100%",flexDirection:"column"}}>
            <div className="d-flex justify-content-start">
                <span style={{textAlign:"right",color:"#fff"}}>
                    Mi configuración.
                </span>
            </div>
            <div style={{width:"100%",marginLeft:10}}>
                <ul>
                    <li style={itemStyle} className="minisizeable">
                        <span style={aStyle} onClick={()=>navigate("/RestorePsw")}>
                            Restaurar contraseña
                        </span>
                    </li>
                    <li style={itemStyle} className="minisizeable">
                        <a style={aStyle} target="_blank" rel="noreferrer" href={`${BACKEND}terminos`}>
                            Políticas de privacidad
                        </a>
                    </li>
                    <li style={itemStyle} className="minisizeable">
                        <a style={aStyle} target="_blank" rel="noreferrer" href={`${BACKEND}terminos_condiciones`}>
                            Terminos y condiciones
                        </a>
                    </li>
                    <li style={itemStyle} className="minisizeable">
                        <a style={aStyle} target="_blank" rel="noreferrer" href={`${BACKEND}devolucion`}>
                            Politicas de devolución
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default ContentConfig;