import React from "react";
import { useConfirmation } from "../Store";
import Terminos123 from "./Terminos123";
import Terminos4 from "./Terminos4";
import StripeForm from "../StripeForm";
const Terminos = ()=>{
    const {
        terminos1,
        terminos2,
        terminos3,
        terminos4
    } = useConfirmation();
    if(!terminos1||!terminos2||!terminos3) return <Terminos123 />
    if(!terminos4) return <Terminos4 />
    return <StripeForm />

}
export default Terminos;