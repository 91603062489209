import Avatar from 'react-avatar';
import { useUser } from "../../../Context/User";
import { IconButton } from '@mui/material';
const RightSide = ()=>{
    const {user,handleClickMenuList} = useUser();
    return(
        <div
            style={{
                display:"flex",
                justifyContent:"flex-start",
                alignItems:"center"
            }}
        >
            {
                    user ? <IconButton onClick={handleClickMenuList}>
                                <Avatar name={`${user.first_name} ${user.second_name}`} round size={30} />
                            </IconButton> :
                            <Avatar style={{cursor:"no-drop"}} name={`I N V`} round size={30} />
            }
        </div>    
    )
}

export default RightSide;