/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect} from 'react';
import { useTimer } from 'react-timer-hook';
import {colors} from "../../../../../Utils/Constants";
function MyTimer({ expiryTimestamp, callback }) {
  const {
    seconds,
    minutes,
    isRunning,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });
  const timerStyle = {
    width:"100%",
    borderRadius:10,
    borderStyle:"solid",
    borderWidth:2,
    borderColor:colors.bgGrayDark,
    padding:10,
    textAlign: 'center'
}
useEffect(()=>{
  if(!isRunning){
    callback()
  }
},[isRunning])
  return (
    <div style={timerStyle}>
      <div style={{fontSize:20,color:"#fff"}}>
        <span>{minutes}</span>:<span>{seconds}</span>
      </div>
      <span style={{fontSize:10,fontWeight:"bold",color:colors.white}}>Al concluir el límite de tiempo tendra que reiniciar la busqueda de boletos.</span>
    </div>
  );
}
export default MyTimer;