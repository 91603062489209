import { Fragment } from "react";
import {FaRegSadCry} from "react-icons/fa";
const NoEvents = ()=>(
    <Fragment>
        <div className="d-flex justify-content-center mt-4">
            <span style={{
                color:"#fff",
                fontSize:14
            }}>Parece que no hay eventos cercanos.</span>
        </div>
        <div className="d-flex justify-content-center mt-1">
            <FaRegSadCry style={{fontSize:40,color:"#fff"}} />
        </div>
    </Fragment>
);
export default NoEvents;